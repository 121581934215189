@import url(https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap);

$content-width: 100%;

@import "minima";

@media screen {
  .site-nav {
    text-transform: uppercase;
    font-family: "Playfair Display", Georgia, serif;
    font-size: 15px;
    color: #221e1d;
  }
  .site-header {
    background: #e6e8db;
    border-color: #efefef;
  }

  .site-title {
    padding: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }

  .site-nav {
    float: right !important;
    margin-right: -15px;
  }

  a {
    color: #971c06;
    text-decoration: none;
    font-family: "Playfair Display", Georgia, serif;

    &:hover {
      box-shadow: none;
      color: #221e1d;
      text-decoration: underline;
    }

    &:visited {
      color: #971c06;
    }
  }
  .page-content.index {
    background: linear-gradient(to bottom, #e6e8db 0%, #fdfdfd 100%);
    width: 100%;
    height: 100%;
    margin-top: auto;
    input[type="text"] {
      background-color: #FFFFFF !important;
      height: 34px;
      width: 80%;
    }

    .glyphicon-search:before {
      content: "\e003";
    }
  } 

  .search {
    background-color: #FFFFFF !important;
    width: 100%;
    height: 100%;
    margin-top: auto;
  }

  .home-search-input {
    display: inline-block;
    float: left;
    width: calc(100% - 10px);
    padding-left: 3em;

  }
  
  .page-content {
    color: #221e1d;
    font-family: "Playfair Display", Georgia, serif;
    font-size: 18px;
    line-height: 1.5em;
    margin: 1.5em auto;
    h2 {
      font-family: "Playfair Display", Georgia, serif;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
      margin: 1.5em auto 0.5em;
    }
    h3 {
      font-family: "Playfair Display", Georgia, serif;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      padding-top: 1em;
    }

    table.partners { border: none; }
    .partners {
      h1 {
        text-align: left;
      }
      h2 {
        font-family: "Playfair Display", Georgia, serif;
        font-size: 18px;
        font-weight: bold;
        text-align: left;
      }
      h3 {
        font-family: "Playfair Display", Georgia, serif;
        font-size: 16px;
        text-align: left;
      }
      img.logo {
        max-width: 100px;
        max-height: 60px;
      }
      td.logo {
        text-align: center;
      }
      td {
        border: none;
        background: #fdfdfd;
      }
    }
    img {
      display: inline-block;
      margin: 0.5em 1.5em;
    }

    .right {
      float: right;
      width: 425px;
    }

    .left {
      float: left;
      width: 425px;
    }

    figure figcaption {
      font-family: "Playfair Display", Georgia, serif;
      font-size: 0.75em;
      padding: 0.5em 1.9em 0.55em;
      width: 350px;
      margin: 0 auto;
    } 

    img.community-logo {
      float: left;
      max-width: 200px;
    }

    .home-catalogo-buttons {
      clear: both;
      padding-top: 1em;
      max-width: 980px;
      width: 90%;
    }

    .browse-catalogo-button {
      color: #fff;
      background-color: #221E1D;
      border-color: #141211;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 1px solid transparent;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.428571429;
      border-radius: 4px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: inline-block;
      margin-bottom: 0;
    }

    .browse-catalogo-list {
      display: inline-block;
      float: left;
      width: calc(100% - 0px);
      padding-left: 1em;
    }

    .browse-catalogo-list ul {
      margin-left: 0 !important;
    }

    .browse-catalogo-list li {
      margin: 0 10px;
      float: left;
    }

  }

  .home {
    h1 {
      display: none;
    }
    h2 {
      font-family: "Playfair Display", Georgia, serif;
      font-size: 30px;
      font-weight: bold;
      margin: 0 0 0.75em;
      text-align: left;
    }

    .post-meta {
      color: #221e1d;
      font-family: "Playfair Display", Georgia, serif;
      font-size: 20px;
      line-height: 1.5em;
      margin: 1.5em auto;
    }
  }

  .contact {
    h1 {
      display: block !important;
      font-family: "Playfair Display", Georgia, serif;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      margin: 0.5em auto 1.5em;
    }
    width: 1024px;
    form {
      margin-bottom: 50px;
      background: #efefef;
      border: 1px solid #efefef;
      padding: 2em;
    }

    input,
    textarea {
      -webkit-appearance: none;
      border-radius: 0;
      font-size: 16px;
    }

    input,
    select,
    textarea {
      display: block;
      color: #221e1d;
      background-color: #ffffff;
      border: 3px solid #e6e8db;
      font-family: "Playfair Display", Georgia, serif;
      padding: 1em;
      margin-bottom: 1em;
      width: 95%;

      &:focus {
        background: #ffffff;
        box-shadow: 0;
        border: 3px solid #adab9d;
        color: #221e1d;
        outline: none;
        padding: 1em;
        transition: border 0.5s ease-in;
      }

      &[type="submit"] {
        background: #adab9d;
        font-weight: 900;
        text-transform: uppercase;
        border-color: #adab9d;

        &:hover {
          background: #ffffff;
          transition: background 1s ease-in;
        }
      }
    }

    textarea {
      resize: vertical;
      min-height: 200px;
    }
  }

  .site-post {
    margin-bottom: 2em;
    border-bottom: 1px solid #000000;

    h2 {
      font-family: "Playfair Display", Georgia, serif;
      font-size: 25px;
      font-weight: bold;
      margin: 0.75em 0 0.75em;
      text-align: left;
    }
  }

  .intro {
    bottom: 0;
    top: 0;
    display: block;
    height: 100vh;
    width: 100%;
  }
  .intro h1 {
    font-family: "Playfair Display", Georgia, serif;
    font-weight: 700;
    letter-spacing: .06em;
    margin: 0;
    padding: 0.5em;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 600px) {
      font-size: 25px;
    }
  
    @media (min-width: 800px) {
      font-size: 32px;
    }
  
    @media (min-width: 1024px) {
      font-size: 42px;
    }
  
    @media (min-width: 1150px) {
      font-size: 51px;
    }
  }


  .intro .small {
    font-family: "Playfair Display", Georgia, serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .06em;
    margin: 0;
    padding: 0.5em;
    text-align: center;
    text-transform: uppercase; 
  
    @media (min-width: 600px) {
      font-size: 12px;
    }
  
    @media (min-width: 800px) {
      font-size: 15px;
    }
  
    @media (min-width: 1024px) {
      font-size: 20px;
    }
  
    @media (min-width: 1150px) {
      font-size: 24px;
    }
  }
  
  .intro .italic {
    font-style: italic;
    letter-spacing: 0;
    text-transform: none;
  }

  .wrap {
    max-width: calc(100% - 100px);
    width: 100%;
    height: calc(97% - 100px);
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 600px) {
      border: 8px solid #000000;
    }
    .center {
      width: 100%;
    }
  }
}

.search-browse-homepage {
  margin: auto;
  max-width: 996px;
  width: 90%;
}

.search-query-form {
  display: inline-block;
  float: left;
  width: calc(100% - 186px);
} 

.intro .wrap .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.site-footer {

  padding: 0px;

  section {
    width: 100%;
    display: block;
    position: relative;
    padding: 5px 10px;
  } 
  .partners {
      text-align: center;
      margin-left: 0px;
  }
  .partners ul {
    list-style: none;
    padding: 0;
    text-align: center;
  }
  .partners ul li {
    display: inline-block;
    max-height: 45px;
    padding: 0.5rem;
    position: relative;
    text-align: center;
  }
  .partners ul li img {
    width: auto;
    max-height: 60px;
    display: block;
    margin: 0 auto;
  }

  .footer-bottom {
    font-family: "Playfair Display", Georgia, serif;
    font-size: 13px;
  }
  
}

dl.compact {
    display: grid;
    grid-template: auto / auto auto;
    width: fit-content;
    column-gap: 1em;
}

dl.compact dt {
    grid-column-start: 1;
}

dl.compact dd {
    grid-column-start: 2;
    margin: 0;
}

ul {
    list-style: none;
}

#side {
  font-size: 14px;
  line-height: 1.5em;
  li {
    padding-bottom: 0.5em;
  }
}

.catalogo-note {
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  padding: 0 15px;
  margin: 10px 0 5px;
}

.catalogo-bibl {
  color: #333;
  font-family: "Cardo", Georgia, serif;
  font-size: 18px;
  line-height: 1.5em;
}
  

span.catalogo-title {
  font-style: italic;
}

span.catalogo-item-label {
  font-size: 18px;
  font-weight: bold;
  padding-right: 5px;
}

.catalogo-item {
  margin: 40px;
  border-bottom: 1px dotted #ddd;
  padding-bottom: 20px;
  &:last-child {
    border: 0;
  }
}

.search-btn {
  color: #fff;
  background-color: #221E1D;
  border-color: #141211;
  height: 34px;
  height: 40px;
  width: 50px;
  margin-left: -6px;
  padding: 5px;
}

.catalogo-browse-button {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  margin: 1em 0.5em 1em 0.5em;
  background: #f1f2eb;
  color: #333;
  border-color: #ccc;
  display: inline-block;
  font-weight: normal;
  font-style: italic;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid #ccc;
  min-width: 110px;

  a:link, a:visited {
    color: #333;
  }
}
